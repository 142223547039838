import { TYPE } from "../actions/types"; 

const initialState = {
    hideHeader:null,
    hideFooter:null,
    tabview:"",
    downloadUrl:"",
    ledgerPay:"",
    dateRange:"",
    updateView:"",
    soldResponse:"",
    bookingHistory:"",
    upcomingBookings:"",
    reqBody:{}
}

export const commonActionsReducer=(state = initialState, action) =>{
    // console.log(action , "actionnnnnnnn");
    switch(action.type){
        case TYPE.HIDE_HEADER:{
          return Object.assign({}, state, {
            hideHeader:action.payload
          })
        }
        case TYPE.HIDE_FOOTER:{
          return Object.assign({}, state, {
            hideFooter:action.payload
          })
        }
        case TYPE.SET_TABVIEW:{
          return Object.assign({}, state, {
            tabview:action.payload
          })
        }
        case TYPE.DOWNLOAD_M:{
          return Object.assign({}, state, {
            downloadUrl:action.payload
          })
        } 
        case TYPE.LEDGER_PAY_M:{
          return Object.assign({}, state, {
            ledgerPay:action.payload
          })
        } 
        case TYPE.UPDATE_BULK_RANGE:{
          return Object.assign({}, state, {
            dateRange:action.payload
          })
        } 
        case TYPE.UPDATE_VIEW:{
          return Object.assign({}, state, {
            updateView:action.payload
          })
        } 
        case TYPE.CHECK_HOTEL_SOLD_OUT:{
          return Object.assign({}, state, {
            soldResponse:action.payload
          })
        } 
        case TYPE.GET_BOOKING_HISTORY:{
          return Object.assign({}, state, {
            bookingHistory:action.payload
          })
        } 
        case TYPE.GET_PENDING_BOOKINGS:{
          return Object.assign({}, state, {
            upcomingBookings:action.payload
          })
        } 
        case TYPE.SET_REQ_BODY:{
          return Object.assign({},state, {
            reqBody:action.payload
          })
        }
        case "clear_req_body" :{
          return Object.assign({},state, {
            reqBody:{}
          })
        }
        default:
            return state;
    }
}