import axios from 'axios';
import { TYPE } from './types';
import * as Constants from '../../constants';


export function checkSessionStatus(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const authApi = Constants.HOTEL_API + '/' + TYPE.AUTH;
    const request = axios
        .post(authApi, data, {
            headers: { "Authorization": `Bearer ${apiToken}` }
        })
        .then(response => {
            if (response.data.status==="SUCCESS" && response.data.data.token && response.data.data.token.length > 20) {
                response.data.selectedHotelID = localStorage.getItem(Constants.SELECTED_HOTEL_ID)
                response.data.selectedHotelName = localStorage.getItem(Constants.SELECTED_HOTEL_NAME)
                response.data.isSessionValid=true
            }else{
                response.data.isSessionValid=false
            }

            return response.data;
        });
    return {
        type: TYPE.AUTH,
        payload: request
    }
}

export function clearSession() {
    localStorage.clear();
    let response = Object();
    response.data=Object();
    response.data.isSessionValid = false
    return {
        type: TYPE.CLEAR_SESSION,
        payload: response
    }
}

