import React, { useEffect, useRef, useState } from "react";
import { USER_DATA } from "../constants";
import { SELECTED_HOTEL_ID, SELECTED_HOTEL_NAME } from "../constants";
import { useDispatch } from "react-redux";
import { Popup } from "semantic-ui-react"

function HotelsDropDown(props) {
  let hotelsRef = useRef();
  const [hotelValueText, setHotelValueText] = useState("");
  const [hotelsDropdown, setHotelsDropdown] = useState(false);
  const [isHotelExists, setIsHotelExists] = useState(true)

  const [hotelName, setHotelName] = useState(
    localStorage.getItem(SELECTED_HOTEL_NAME)
  );

  let hotelsArr = JSON.parse(localStorage.getItem(USER_DATA));

  const dispatch = useDispatch()

  useEffect(() => {
    let handler = (e) => {
      if (hotelsDropdown) {
        if (!hotelsRef.current.contains(e.target)) {
          setHotelsDropdown(false);
          setHotelValueText("")
          setIsHotelExists(true)
        }
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleHotelsDropdown = () => {
    setHotelsDropdown(!hotelsDropdown);
    setHotelValueText("")
    setIsHotelExists(true)


    setTimeout(() => {
      const el = document.getElementById("hotelsDivBlock")

      if (el !== null) {
        el.scrollTo(0,
          findPosition(document.getElementById(localStorage.getItem(SELECTED_HOTEL_ID))))
      }

      function findPosition(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
          do {
            currenttop += obj.offsetTop;
          } while ((obj = obj.offsetParent));
          return [currenttop - 150];
        }
      }

      onFocusInputFeild()

    }, 300)

  };

  const onHandleHotelSearchInput = (event) => {
    setHotelValueText(event.target.value);

    searchIsHotelExits(event.target.value)
  };

  const searchIsHotelExits = (value) => {
    const searchedHotels = hotelsArr.hotels.filter((option) =>
      option.hotel_name
        .toLowerCase()
        .includes(value.toLocaleLowerCase()))

    if (searchedHotels.length >= 1) {
      setIsHotelExists(true)
    }
    else {
      setIsHotelExists(false)
    }
  }

  const onSelectHotel = (option) => {
    if (option.hotel_id !== localStorage.getItem(SELECTED_HOTEL_ID)) {

      setHotelValueText("");
      setHotelName(option.hotel_name);
      setHotelsDropdown(!hotelsDropdown);

      props.session.selectedHotelID = option.hotel_id;
      props.session.selectedHotelName = option.hotel_name;

      let userData = JSON.parse(localStorage.getItem(USER_DATA));

      let selectedHotel = userData.hotels.find(
        (hotel) => hotel.hotel_id === option.hotel_id
      );

      localStorage.setItem(SELECTED_HOTEL_ID, selectedHotel.hotel_id);
      localStorage.setItem(SELECTED_HOTEL_NAME, selectedHotel.hotel_name);

      if (selectedHotel.agreement_type === "DISPLAY") {
        props.history.push("/agreement");
      }
      else if (
        selectedHotel.agreement_type !== "DISPLAY" &&
        selectedHotel.allow_foreign_guest === "DISPLAY"
      ) {
        props.history.push("/foreign-guest");
      }
      else {
        {/* We cannont re route to the same route after changing the hotel*/ }
        props.history.push(props.history.location.pathname === "/" ? "/summary" : "/");
      }

      //Dispatching the actions to clear the previous hotel responses on redux store
      dispatch({ type: "clear_hotel_data_response" })
      dispatch({ type: "clear_soldOut_res" })
      dispatch({ type: "clear_finance_summary_res" })
      dispatch({ type: "clear_rooms_api_res" })
      dispatch({ type: "clear_brevi_bookings_res" })
      dispatch({ type: "clear_holida_bookings_res" })
    }

  };

  const onFocusInputFeild = () => {
    document.getElementById("hotelInputFeild").focus()
  }


  return (
    <div
      ref={hotelsRef}
      className={`hotelsDropDown ${hotelsDropdown ? "open" : ""}`}
      style={{
        cursor: "pointer",
        paddingInline: 15,
        zIndex: 20,
      }}
      onClick={() => !hotelsDropdown && handleHotelsDropdown()}

    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "95%" }}>
          {!hotelsDropdown ?
            (
              <div
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <input
                  className="hotelNameTxt"
                  value={
                    hotelName === "" ? "Select Hotel" : hotelName
                  }
                  style={{
                    color: "#000000",
                    fontFamily: "Poppins",
                    width: "100%",
                    fontWeight: 400,
                    paddingLeft: 15,
                    borderWidth: 0,
                    borderStyle: "none",
                    outline: "none",
                  }}
                />
              </div>
            )
            :
            (
              <div
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <svg
                  onClick={() => onFocusInputFeild()}
                  className="search_icon_svg"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="14.666"
                    cy="14.6666"
                    r="7.33333"
                    stroke="#9B97A0"
                    strokeWidth="1.33333"
                  />
                  <path
                    d="M20 20L25.6569 25.6569"
                    stroke="#9B97A0"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                  />
                </svg>

                <input
                  placeholder={
                    hotelName === "" ? "Search" : hotelName
                  }
                  value={hotelValueText}
                  onChange={(event) => onHandleHotelSearchInput(event)}
                  className="hotelNameTxt"
                  style={{
                    height: "100%",
                    width: "100%",
                    borderWidth: 0,
                    borderStyle: "none",
                    outline: "none",
                    marginLeft: "1rem",
                    backgroundColor: "transparent",
                  }}
                  id="hotelInputFeild"
                />
              </div>
            )}
        </div>

        <div
          className={`hotelDropDownIcon ${hotelsDropdown ? "open" : ""
            }`}
          onClick={() => handleHotelsDropdown()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="arrow_icon_svg"
            viewBox="0 0 15 11"
            fill="none"
          >
            <path
              d="M6.71285 9.99541C7.11323 10.5064 7.88677 10.5064 8.28715 9.99541L14.4604 2.11677C14.9747 1.46043 14.5071 0.5 13.6733 0.5H1.32672C0.49291 0.5 0.025305 1.46044 0.53957 2.11677L6.71285 9.99541Z"
              fill="#000000"
            />
          </svg>
        </div>
      </div>

      {hotelsDropdown && (
        <div className="hotelsOuterCls" id="hotelsDivBlock">
          {isHotelExists ?
            hotelsArr.hotels.map((option) => {
              return (
                option.hotel_name
                  .toLowerCase()
                  .includes(hotelValueText.toLocaleLowerCase()) && (
                  <div
                    onClick={() => onSelectHotel(option)}
                    style={{ backgroundColor: hotelName === option.hotel_name ? "#F6F2FF" : "" }}
                    className="hotelOptions"
                    key={option.hotel_id}
                    id={option.hotel_id}
                  >
                    <span className="hotels_txt_value" style={{ paddingLeft: 10, fontWeight: hotelName === option.hotel_name ? "600" : "500" }}>
                      {option.hotel_name}
                    </span>

                    {option.active_channel_manager === "1" &&
                      <Popup
                        trigger={
                          <div style={{
                            display: 'flex', justifyContent: 'space-between', alignItems: 'center'
                          }}
                          >
                            <img src=
                              {option.active_channel_manager_name === "STAAH" ?
                                "https://site-img-res-new.s3.ap-south-1.amazonaws.com/extranet-new/STAAH.png"
                                :
                                "https://site-img-res-new.s3.ap-south-1.amazonaws.com/extranet-new/AXISROOMSLOGO.jpg"
                              }
                              style={{ width: '1.25rem', height: '1.25rem', borderRadius: "1rem" }}
                            />
                            <p className="__staahTag">
                              {option.active_channel_manager_name}
                            </p>
                          </div>
                        }
                        content='Channel Manager'
                        position='top center'
                      />

                    }
                  </div>
                )
              );
            })
            :
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: "100%" }}>
              <p className="hotels_txt_value">
                No Results...
              </p>
            </div>
          }
        </div>
      )}
    </div>
  );
}

export default HotelsDropDown;
