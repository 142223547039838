import { TYPE } from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case TYPE.POLICY_AGREE_DENY:
      return { ...state, response: action.payload };

    default:
      return state;
  }
}
