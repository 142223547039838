import axios from 'axios';

import { TYPE } from './types';

import * as Constants from '../../constants';

export const hideHeader= (data) =>({
    type:TYPE.HIDE_HEADER,
    payload:data
})
export const hideFooter= (data) =>({
    type:TYPE.HIDE_FOOTER,
    payload:data
})
export const setTabView= (data) =>({
    type:TYPE.SET_TABVIEW,
    payload:data
})
export const updateView= (data) =>({
    type:TYPE.UPDATE_VIEW,
    payload:data
})

export const setReqBody = (data)=>({
  type:TYPE.SET_REQ_BODY,
  payload:data
})

export function downloadDocumentMobile(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    let loginApi;
    if(data.type == "booking_voucher")
    {
      loginApi =  Constants.FINANCE + '/' + "download"+ '/'+data.type+'/'+data.booking_voucher_file +"?login_user_id=" +data.login_user_id;
    }
    else
    {
    loginApi = Constants.FINANCE + '/' + "download"+ '/'+data.type+'/'+data.id + "?hotel_id=" + data.hotel_id + "&login_user_id=" + data.login_user_id 
    // console.log("url for other voucher ===>",loginApi)

    }

    const request = axios
        .get(loginApi, {
            headers: { "Authorization": `Bearer ${apiToken}` }
        })
        .then(response => response.data);

    return {
        type: TYPE.DOWNLOAD_M,
        payload: request
    }
}

export function initiateLedgerPayMobile(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const endPoint = Constants.PAYMENT + '/' + "ledger-pay";
    const request = axios
        .post(endPoint, data, {
            headers: { "Authorization": `Bearer ${apiToken}` }
        })
        .then(response => response.data);
    return {
        type: TYPE.LEDGER_PAY_M,
        payload: request
    }
}

export function updateBulkRange(data) {
    
    return {
        type: TYPE.UPDATE_BULK_RANGE,
        payload: data
    }
}
export function getBookingHistory_m(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const loginApi = Constants.HOTEL_API + "/" + TYPE.GET_BOOKING_HISTORY;
    const request = axios
      .post(loginApi, data, { headers: { Authorization: `Bearer ${apiToken}` } })
      .then((response) => response.data);
  
    return {
      type: TYPE.GET_BOOKING_HISTORY,
      payload: request,
    };
  }
  export function getUpcomingBookings_m(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const bookingHistoryApi =
      Constants.HOTEL_API + "/" + TYPE.GET_PENDING_BOOKINGS;
    const request = axios
      .post(bookingHistoryApi, data, {
        headers: { Authorization: `Bearer ${apiToken}` },
      })
      .then((response) => response.data);
  
    return {
      type: TYPE.GET_PENDING_BOOKINGS,
      payload: request,
    };
  }