import { TYPE } from '../actions/types'
const intialState = {
    roomsApiRes: {},
    roomsApiLoader: true
}

export default function (state = intialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.GET_HOTEL_ROOMS:
            state.roomsApiRes = response
            state.roomsApiLoader = false
            return { ...state, response: action.payload }
        case TYPE.UPDATE_HOTEL_ROOMS:
            return { ...state, response: action.payload }
        case "clear_rooms_api_res":
            state.roomsApiLoader = true
            state.roomsApiRes = {}

            return {...state}
        default:
            return state;
    }
}

