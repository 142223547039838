import { TYPE } from '../actions/types'

export default function (state = {}, action) {
    switch (action.type) {
        case TYPE.CLICK_WRAP_AGR_ACCEPTANCE:
            return { ...state, response: action.payload }

        default:
            return state;
    }
}

