import React, { useState, useEffect, useRef } from "react";
import newExtranetImages from "../../../assets";
import { Menu, Image } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";

function SideNavbar(props) {
  const [activeItem, setActiveItem] = useState("")
  const [scrollPosition, setScrollPosition] = useState("")
  const sessionResponse = useSelector((state) => state.session.response)
  const itemRef = useRef()

  const menuItemList = [
    {
      id: "summary",
      name: "home",
      path: "/summary",
      display: true,
      displayText: "Summary",
      isSelected: props.location.pathname == "/" || props.location.pathname === "/summary",
      icon: newExtranetImages.SBSummaryIcon,
    },
    {
      id: 'bookings',
      name: "bookings",
      path: "/bookings",
      display: true,
      displayText: "Bookings",
      isSelected: props.location.pathname.includes("/bookings"),
      icon: newExtranetImages.SBBookingIcon,
    },
    {
      id: 'inventory',
      name: "manage rooms",
      path: "/inventory",
      display: true,
      displayText: "Inventory",
      isSelected:
        props.location.pathname.includes("/inventory") ||
        props.location.pathname.includes("/manage-rooms/room-slots"),
      icon: newExtranetImages.SBInventoryIcon,
    },
    {
      id: "createRoom",
      name: "create room",
      path: "/create-room-category",
      display: true,
      isSelected:
        props.location.pathname.includes("/create-room-category") ||
        props.location.pathname.includes("/room-category"),
      displayText: "Rooms",
      icon: newExtranetImages.CreateRoomIcon,
    },
    {
      id: "finance",
      name: "hotel finance",
      path: "/hotelfinance",
      display: true,
      isSelected: props.location.pathname.includes("/hotelfinance"),
      displayText: "Finance",
      icon: newExtranetImages.SBFinanceIcon,
    },
    {
      id: "profile",
      name: "hotel profile",
      path: "/profile",
      display: true,
      isSelected: props.location.pathname.includes("/profile"),
      displayText: "Profile",
      icon: newExtranetImages.SBProfileIcon,
    },
    {
      id: "amenities",
      name: "amenities",
      path: "/amenities",
      display: true,
      isSelected: props.location.pathname.includes("/amenities"),
      displayText: "Amenities",
      icon: newExtranetImages.SBAmenityIcon,
    },
    {
      id: "staff",
      name: "staff",
      path: "/user-access",
      display: true,
      isSelected: props.location.pathname.includes("/user-access"),
      displayText: "Staff",
      icon: newExtranetImages.SBStaffIcon,
    },

    {
      id: "policy",
      name: "policy-updates",
      path: "/policy",
      display: true,
      isSelected: props.location.pathname.includes("/policy"),
      displayText: "Policy",
      icon: newExtranetImages.SBPolicyIcon,
    },
    {
      id: "property",
      name: "hotel property",
      path: "/hotel-property",
      display: true,
      isSelected: props.location.pathname.includes("/hotel-property"),
      displayText: "Properties",
      icon: newExtranetImages.SBPropertyIcon,
    },
  ];

  useEffect(() => {
    let selectedItem = menuItemList.filter((item) => item.path === props.location.pathname)

    if (selectedItem[0] !== undefined) {
      onScroll(selectedItem[0].id)
      setActiveItem(selectedItem[0].id)
    }
    else{
      onScroll(menuItemList[0].id)
      setActiveItem(menuItemList[0].id)
    }

  }, [props.location.pathname])
  

  const handleMenuItemClick = (name, path) => {
    props.history.push(path);

    setActiveItem(name);
  };

  const onScroll = (type) => {
    // setActiveItem(type)
    const el = document.getElementById(type)

    if (el !== null) el.scrollIntoView({ behavior: 'smooth' })
  }

  const onScrollMenu = (e) => {
    // // const ele = document.getElementById("refItems").scrollY
    // // console.log("ele",ele)
    // console.log("clientHeight",itemRef.current.clientHeight)
    // console.log("scrollHeight",itemRef.current.scrollHeight)
    console.log("scrollTop", itemRef.current.scrollTop);

    if (itemRef.current.scrollHeight - itemRef.current.scrollTop - itemRef.current.clientHeight <= 0) {
      // alert("Bottom")
      setScrollPosition("bottom")
    }
    else if (itemRef.current.scrollTop === 0) {
      setScrollPosition("top")
    }
    else {
      setScrollPosition("between")
    }
  }


  //   let el=document.getElementById('el');
  // el.addEventListener('scroll', function(e) {
  //     if (this.scrollHeight - this.scrollTop - this.clientHeight<=0) {
  //         alert('Bottom');
  //     }
  // });


  return (
    <>

      {sessionResponse && sessionResponse.isSessionValid &&
        !isMobile &&
        !props.location.pathname.includes("/new-user") &&
        !props.location.pathname.includes("/help-support") &&
        !props.location.pathname.includes("/getting-started-guide") &&
        !props.location.pathname.includes("/internal-server-error") ?

        <div className="ext_side_navigation_bar_parent">
          <div className="ext_side_nav_child">

            <div className="ext_side_nav_arrow_cls" >
              <svg style={{ opacity: scrollPosition === "top" ? 0.3 : 1, pointerEvents: scrollPosition === "top" ? "none" : "" }} onClick={() => onScroll("summary")} xmlns="http://www.w3.org/2000/svg" className="ext_side_nav_arrow" viewBox="0 0 20 12" fill="none">
                <path d="M18.03 11.6699L19.8 9.89992L9.9 -7.82013e-05L0 9.89992L1.77 11.6699L9.9 3.53992L18.03 11.6699Z" fill="#ECECEC" />
              </svg>
            </div>

            <div ref={itemRef} className="ext_side_nav_menu_items" id="refItems" onScroll={(e) => onScrollMenu(e)}>

              {menuItemList.map((item) => (
                <div
                  className={`sb-item ${item.isSelected ? "selected" : ""}`}
                  id={item.id}
                  key={item.name}
                  onClick={() => handleMenuItemClick(item.name, item.path)}
                >
                  <Image className="sb-icon" src={item.icon} />
                  <p
                    className="__sbItemTxt"
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {item.displayText}
                  </p>
                </div>
              ))}

            </div>

            <div className="ext_side_nav_arrow_cls" >
              <svg style={{ opacity: scrollPosition === "bottom" ? 0.3 : 1, pointerEvents: scrollPosition === "bottom" ? "none" : "" }} onClick={() => onScroll("property")} xmlns="http://www.w3.org/2000/svg" className="ext_side_nav_arrow" width="20" height="12" viewBox="0 0 20 12" fill="none">
                <path d="M18.03 0L19.8 1.77L9.9 11.67L0 1.77L1.77 0L9.9 8.13L18.03 0Z" fill="#ECECEC" />
              </svg>
            </div>

          </div>


        </div>

        : null
      }

    </>
  );
}

export default withRouter(SideNavbar)