import { TYPE } from '../actions/types'
const intialState = {
    soldOutDatesRes: {},
    soldOutDatesApiLoader: true
}

export default function (state = intialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.GET_SOLD_OUT_DATES:
            state.soldOutDatesRes = response
            state.soldOutDatesApiLoader = false
            return { ...state, response: action.payload }
        case TYPE.UPDATE_SOLD_OUT_DATE:
            return { ...state, response: action.payload }
        case TYPE.HOTEL_SOLD_OUT:
            return { ...state, response: action.payload }
        case TYPE.HOTEL_BULK_UNSOLD_OUT:
            return { ...state, response: action.payload }
        case "clear_soldOut_res":
            state.soldOutDatesApiLoader = true
            state.soldOutDatesRes = {}
            return {...state}
        default:
            return state;
    }
}

