import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Divider, Menu, Image, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import newExtranetImages from "../../../assets";

const Footer = (props) => {
  const selectorState = useSelector((state) => state);
  const bottomTabFlag = selectorState.commonActionsReducer.hideHeader;
  const bottomTabFlagFooter = selectorState.commonActionsReducer.hideFooter;
  const [activeItem, setActiveItem] = useState("summary");
  const handleItemClick = (e, { name }) => {
    let permissions = props.session.data.permissions;
    setActiveItem(name);
    if (name === "bookings" && permissions.uep_view_bookings === "1") {
      // if (permissions.uep_view_bookings === "1") {
      props.history.push("/bookings");
      // }
      // else {
      //     props.history.push('/unauthorized')
      // }
    } else if (
      name === "inventory" &&
      permissions.uep_user_rate_access !== "na"
    ) {
      props.history.push("/inventory-home");
    } else if (name === "finance" && permissions.uep_finance == "1") {
      props.history.push("/hotel-finance/summary");
    } else if (name === "profile" && permissions.uep_hotel_profile == "1") {
      props.history.push("/profile");
    } else if (name === "summary" && permissions.uep_hotel_profile == "1") {
      props.history.push("/summary");
    } else {
      props.history.push("/unauthorized");
    }
  };
  useEffect(() => {
    if (
      props.history.location.pathname.includes("/inventory") ||
      props.history.location.pathname.includes("/holida-inventory") ||
      props.history.location.pathname.includes("/manage")
    ) {
      setActiveItem("inventory");
    } else if (props.history.location.pathname.includes("/hotel-finance")) {
      setActiveItem("finance");
    } else if (props.history.location.pathname.includes("/bookings")) {
      setActiveItem("bookings");
    } else if (props.history.location.pathname.includes("/profile")) {
      setActiveItem("profile");
    } else if (props.history.location.pathname.includes("/summary")) {
      setActiveItem("summary");
    } else if (props.history.location.pathname.includes("/unauthorized")) {
      setActiveItem(activeItem);
    } else if (props.history.location.pathname.includes("/")) {
      setActiveItem("summary");
    }
  }, [props.history.location]);
  return (
    <div>
      {isMobile ? // <footer>
        //     {/* <h6>&copy; Copyright Brevistay Hospitality Pvt. Ltd.</h6>
        //     <h6>
        //         For Support Mail us  at  <a href="mailto:hotels@brevistay.com" target="_top">hotels@brevistay.com</a>
        //     </h6> */}
        // </footer>
        null : (
          <footer>
            <h3>&copy; Copyright Brevistay Hospitality Pvt. Ltd.</h3>
            <h5>
              For Support Mail us at{" "}
              <a href="mailto:hotels@brevistay.com" target="_top">
                hotels@brevistay.com
              </a>
            </h5>
          </footer>
        )}
      {isMobile &&
        !bottomTabFlag &&
        !bottomTabFlagFooter &&
        props.history.location.pathname !== "/login" &&
        props.history.location.pathname !== "/holida-inventory/bulk-update" &&
        props.history.location.pathname !== "/create-room-category" &&
        !props.location.pathname.includes("/room-category")  &&
        !props.location.pathname.includes("/rate-plan")  &&
        props.history.location.pathname !== "/internal-server-error" &&
         (
          <>
            <div
              style={{
                backgroundColor: "white",
                position: "fixed",
                width: "100%",
                zIndex: 3,
                bottom: "0vw",
              }}
            >
              <Menu
                compact
                secondary
                pointing
                style={{
                  height: "55px",
                  marginBottom: 0,
                  width: "100%",
                  fontFamily: "Poppins",
                  boxShadow: "0px -1px 4px rgba(0, 0, 0, 0.25)",
                  paddingBottom: "1px",
                }}
                icon="labeled"
                fluid
                widths={5}
              >
                <Menu.Item
                  style={{ paddingBottom: "0.4rem" }}
                  name="summary"
                  active={activeItem === "summary"}
                  onClick={handleItemClick}
                >
                  <Image
                    wrapped
                    verticalAlign="middle"
                    src={
                      activeItem === "summary"
                        ? "/assets/Hotel_Summary_m.svg"
                        : newExtranetImages.summary_m
                    }
                    style={{
                      width: activeItem === "summary" ? "20px" : "30px",
                      height: activeItem === "summary" ? "18px" : "25px",
                      float: "right",
                      paddingTop: activeItem === "summary" ? "" : "-0.3rem",
                    }}
                  />
                  {/* Home */}
                  <span
                    style={{ fontSize: "0.7rem", marginTop: "6px" }}
                    className={
                      activeItem === "summary"
                        ? "colorFooter"
                        : "colorFooterDisabled"
                    }
                  >
                    Summary
                  </span>
                </Menu.Item>
                <Menu.Item
                  style={{ marginLeft: "0em", paddingBottom: "0.4rem" }}
                  name="bookings"
                  active={activeItem === "bookings"}
                  onClick={handleItemClick}
                >
                  <Image
                    wrapped
                    verticalAlign="middle"
                    src={
                      activeItem === "bookings"
                        ? "/assets/Bookings_m.svg"
                        : newExtranetImages.booking_m
                    }
                    style={{
                      width: activeItem === "bookings" ? "20px" : "30px",
                      height: activeItem === "bookings" ? "18px" : "25px",
                      float: "right",
                      paddingTop: activeItem === "bookings" ? "" : "-0.3rem",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "0.7rem",
                      marginTop: "6px",
                      marginLeft: "",
                    }}
                    className={
                      activeItem === "bookings"
                        ? "colorFooter"
                        : "colorFooterDisabled"
                    }
                  >
                    Bookings
                  </span>
                </Menu.Item>
                <Menu.Item
                  style={{ marginLeft: "0em", paddingBottom: "0.4rem" }}
                  name="finance"
                  active={activeItem === "finance"}
                  onClick={handleItemClick}
                >
                  <Image
                    wrapped
                    verticalAlign="middle"
                    src={
                      activeItem === "finance"
                        ? "/assets/Hotel_Finance_m.svg"
                        : newExtranetImages.finances_m
                    }
                    style={{
                      width: activeItem === "finance" ? "20px" : "32px",
                      height: activeItem === "finance" ? "18px" : "25px",
                      float: "right",
                      paddingTop: activeItem === "finance" ? "" : "-0.3rem",
                    }}
                  />
                  <span
                    style={{ fontSize: "0.7rem", marginTop: "0.4rem" }}
                    className={
                      activeItem === "finance"
                        ? "colorFooter"
                        : "colorFooterDisabled"
                    }
                  >
                    Finance
                  </span>
                </Menu.Item>
                <Menu.Item
                  style={{ marginLeft: "0em", paddingBottom: "0.4rem" }}
                  name="inventory"
                  active={activeItem === "inventory"}
                  onClick={handleItemClick}
                >
                  <Image
                    wrapped
                    verticalAlign="middle"
                    src={
                      activeItem === "inventory"
                        ? "/assets/Manage_Rooms_m.svg"
                        : newExtranetImages.rooms_m
                    }
                    style={{
                      width: activeItem === "inventory" ? "22px" : "35px",
                      height: activeItem === "inventory" ? "18px" : "25px",
                      float: "right",
                      paddingTop: activeItem === "inventory" ? "" : "-0.3rem",
                    }}
                  />
                  <span
                    style={{ fontSize: "0.7rem", marginTop: "6px" }}
                    className={
                      activeItem === "inventory"
                        ? "colorFooter"
                        : "colorFooterDisabled"
                    }
                  >
                    Inventory
                  </span>
                </Menu.Item>
                <Menu.Item
                  style={{ marginLeft: "0em", paddingBottom: "0.4rem" }}
                  name="profile"
                  active={activeItem === "profile"}
                  onClick={handleItemClick}
                >
                  <Image
                    wrapped
                    verticalAlign="middle"
                    src={
                      activeItem === "profile"
                        ? newExtranetImages.New_Hotel_Profile_blue
                        : newExtranetImages.profile_m
                    }
                    style={{
                      width: activeItem === "profile" ? "25px" : "35px",
                      height: activeItem === "profile" ? "18px" : "25px",
                      float: "right",
                      paddingTop: activeItem === "profile" ? "" : "-0.3rem",
                    }}
                  />
                  <span
                    style={{ fontSize: "0.7rem", marginTop: "6px" }}
                    className={
                      activeItem === "profile"
                        ? "colorFooter"
                        : "colorFooterDisabled"
                    }
                  >
                    Profile
                  </span>
                </Menu.Item>
              </Menu>
            </div>
          </>
        )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    session: state.session.response,
  };
};

export default connect(mapStateToProps)(withRouter(Footer));
