import { TYPE } from '../actions/types'

export default function (state = {}, action) {
    switch (action.type) {
        case TYPE.LINKED_ACCOUNT_HOTEL_LIST:
            return { ...state, response: action.payload }
        case "hideFooter":
            return { ...state, response: action.payload }

        default:
            return state;
    }
}

