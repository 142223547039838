import { TYPE } from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case TYPE.GET_ADMIN_HOTELS:
      return { ...state, response: action.payload };
    case TYPE.GET_ALL_MANAGERS:
      return { ...state, response: action.payload };
      case TYPE.EXTRANET_ADD_MANAGER:
        return { ...state, response: action.payload };
        case TYPE.EXTRANET_REMOVE_MANAGER:
          return { ...state, response: action.payload };
      
    default:
      return state;
  }
}
