import { TYPE } from '../actions/types'

export default function (state = {}, action) {
    switch (action.type) {
        case TYPE.GET_STATES:
            return { ...state, response: action.payload }
        case TYPE.GET_CITIES:
            return { ...state, response: action.payload }
        case TYPE.GET_AREA:
            return { ...state, response: action.payload }
        case TYPE.ADD_NEW_CITY:
            return { ...state, response: action.payload }
        case TYPE.ADD_NEW_AREA:
            return { ...state, response: action.payload }
        case TYPE.SEARCH_ADMIN:
            return { ...state, response: action.payload }
        case TYPE.LINK_ADMIN_WITH_HOTEL:
            return { ...state, response: action.payload }
        case TYPE.UPDATE_HOTEL:
            return { ...state, response: action.payload }
        case TYPE.GET_HOTEL_DETAILS:
            return { ...state, response: action.payload }
        case TYPE.GET_UNLISTED_HOTELS:
            return { ...state, response: action.payload }
        case TYPE.UPDATE_NEARBY_AREAS:
            return { ...state, response: action.payload }
        case TYPE.UPLOAD_HOTEL_IMAGES:
            return { ...state, response: action.payload }
        case TYPE.GET_CLOSED_HOTELS:
            return { ...state, response: action.payload }
        case TYPE.ADD_NEW_ACCOUNT:
            return { ...state, response: action.payload }
        case TYPE.SEARCH_ACCOUNT:
            return { ...state, response: action.payload }
        case TYPE.LINK_ACCOUNT:
            return { ...state, response: action.payload }
        case TYPE.UNLINK_ACCOUNT:
            return { ...state, response: action.payload }
        case TYPE.UPLOAD_REG_DOCS:
            return { ...state, response: action.payload }
        case TYPE.GST:
            return { ...state, response: action.payload }
        default:
            return state;
    }
}




