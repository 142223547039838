import { TYPE } from "../actions/types";

const initialState = {
  getFulldayRes: {},
  editFulldayRes: {},
  bulkEditFullDayRes: {},
};

export default function (
  state = initialState,
  action,
  response = action.payload
) {
  switch (action.type) {
    case TYPE.GET_FULL_DAY_ROOMS:
      state.getFulldayRes = response;
      return { ...state, response: action.payload };
    case TYPE.EDIT_FULL_DAY_ROOMS:
      state.editFulldayRes = response;
      return { ...state, response: action.payload };
    case TYPE.BULK_EDIT_FULL_DAY:
      state.bulkEditFullDayRes = response;
      return { ...state, response: action.payload };

    default:
      return state;
  }
}
