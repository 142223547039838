import { TYPE } from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case TYPE.SA_UPDATE_HOTEL_SETTINGS:
      return { ...state, response: action.payload };
     

    default:
      return state;
  }
}
