import React from "react";
import { Button, Message, Form, Input } from "semantic-ui-react";
import {
  changePassword,
  changePasswordUsingOtp,
  sendOtpToChangePassword,
} from "../../../redux/actions/user_actions";
import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

import {
  actionResponseHandler,
  actionErrorHandler,
} from "../../utils/responseHandler";
import ChangePasswordMobile from "../../ManagePassword/managePassword_m";
let interval = null;
export default function ChangePassword(props) {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [seconds, setSeconds] = useState(120);
  const [firstOtpSent, setFirstOtpSent] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [formData, setFormData] = useState({
    old_password: {
      element: "input",
      value: "",
      label: false,
      labelText: "Old Password",
      config: {
        name: "password_input",
        type: "password",
        placeholder: "Enter your Password",
      },
      validation: {
        required: true,
        minLen: 6,
      },
      valid: false,
      touched: false,
      validationMessage: "",
    },
    new_password: {
      element: "input",
      value: "",
      label: false,
      labelText: "New Password",
      config: {
        name: "password_input",
        type: "password",
        placeholder: "Enter New Password",
      },
      validation: {
        required: true,
        minLen: 6,
      },
      valid: false,
      touched: false,
      validationMessage: "",
    },
    confirm_password: {
      element: "input",
      value: "",
      label: false,
      labelText: "Confirm Password",
      config: {
        name: "password_input",
        type: "password",
        placeholder: "Confirm New Password",
      },
      validation: {
        required: true,
        minLen: 6,
      },
      valid: false,
      touched: false,
      validationMessage: "",
    },
  });
  const [open, setOpen] = useState(false);
  const [vertical, setVertical] = useState("bottom");
  const [horizontal, setHorizontal] = useState("center");
  const [errorMsg, setErrorMsg] = useState("");
  const [usePassword, setUsePassword] = useState(false);
  const [isFieldDisabled, setIsFieldDisabled] = useState(true);
  const [otp, setOtp] = useState("");
  const [otpSendMobile, setOtpSendMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validateMsg, setValidateMsg] = useState("");
  const [otpSentMsg, setOtpSentMsg] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  const updateForm = (newState) => {
    setFormData(newState);
  };
  const otpView = () => {
    setUsePassword(!usePassword);
  };
  const submitForm = (event) => {
    event.preventDefault();
    let dataToSubmit = {};
    let formIsValid = true;
    for (let key in formData) {
      dataToSubmit[key] = formData[key].value;
    }
    for (let key in formData) {
      formIsValid = formData[key].valid && formIsValid;
    }
    if (formIsValid) {
      changeUserPassword(dataToSubmit);
    } else {
      this.showSnackBar("Please enter data in all fields");
    }
  };

  const changeUserPassword = (data) => {
    data.user_access_type = props.session.data.user_access_type;
    data.hotel_id = props.session.data.login_user_id;
    let response = null;
    console.log("changepassword", data);
    props
      .dispatch(changePassword(data))
      .then((response) => {
        actionResponseHandler(response, props)
          .then((response) => {
            showSnackBar(response.payload.msg);
            props.history.push("/");
          })
          .catch((response) => {
            showSnackBar(response.payload.msg);
          });
      })
      .catch((error) => {
        actionErrorHandler(error, props).then((error) => {
          showSnackBar(response.payload.msg);
        });
      });
  };
  const showSnackBar = (message) => {
    setErrorMsg(message);
    displaySnackBar();
  };
  const displaySnackBar = (state) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const timerView = () => {
    let minute = "";
    let second = "";
    if (seconds == 120) {
      minute = "02";
    } else if (seconds < 120 && seconds > 59) {
      minute = "01";
    } else {
      minute = "00";
    }
    if (seconds == 120 || seconds == 60) {
      second = "00";
    } else if (seconds < 69 && seconds > 60) {
      second = seconds - 60;
    } else if (seconds < 120 && seconds > 60) {
      second = seconds - 60;
    } else {
      second = seconds;
    }
    return (seconds < 70 && seconds > 60) || (seconds < 10 && seconds >= 0) ? (
      <span >
        {minute}:0{second}
      </span>
    ) : (
      <span >
        {minute}:{second}
      </span>
    );
  };
  useEffect(() => {
    if (seconds == 0) {
      timer()
    }
  }, [seconds])
  const timer = () => {
    if (seconds !== 0) {
      interval = setInterval(
        () =>
          setSeconds(prevState => prevState - 1),
        1000
      );
    }
    else {
      clearInterval(interval, setSeconds(120))
    }

  };



  const getOtp = () => {
    timer();
    setIsFieldDisabled(false);
    setOtpSentMsg("Otp Sent");
    setOtpSendMobile("");
    // setIsOtpSent(true);
    const data = {
      user_id: props.session.data.login_user_id,
    };
    console.log("dispatchingData", data);
    let response = null;
    props
      .dispatch(sendOtpToChangePassword(data))
      .then((response) => {
        // response = props.passwordChange.response;
        setOtpSendMobile(response.payload.msg);
        setIsOtpSent(true)
      })
      .catch(() => { });

    setTimeout(() => setOtpSentMsg(""), 5000);
    // setTimeout(() => setOtpSendMobile(""), 10000);
  };
  const userData = (type, event) => {
    setValidateMsg("");

    if (type == "otp") {
      setOtp(event.value);
    } else if (type == "password") {
      setPassword(event.value);
    } else {
      setConfirmPassword(event.value);
    }
  };
  const changeUserPasswordUsingOtp = () => {
    timer();
    setIsFieldDisabled(false);
    const data = {
      user_id: props.session.data.login_user_id,
      otp: otp,
      new_password: password,
      confirm_password: confirmPassword,
    };
    let response = null;
    props
      .dispatch(changePasswordUsingOtp(data))
      .then((response) => {
        console.log("response", response);
        showSnackBar(response.payload.msg);
        props.history.push("/");
      })
      .catch((error) => {
        actionErrorHandler(error, props).then((error) => {
          showSnackBar(response.payload.msg);
        });
      });
  };
  const validateUserData = () => {
    let msg = "";
    if (confirmPassword.length >= 6 && password.length >= 6) {
      if (confirmPassword == password) {
        changeUserPasswordUsingOtp();
      } else msg = "Passwords Doesn't Match";
    } else {
      msg = "Password Must be greater than 6 Characters";
    }
    setValidateMsg(msg);
  };
  console.log(seconds);
  return (

    <>

      {isMobile ? (
        <div>
          <ChangePasswordMobile props />
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            backgroundColor: "#F1F1F1",
            width: "100%"
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "0.75rem",
              height: "46.5rem",
              width: "100%",
              display: 'flex',
              flexDirection: "column",
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                width: "26rem",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "start",
              }}
            >
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "2.25rem",
                  alignItems: "center",
                  color: "black",
                }}
              >
                Change Password
              </p>
              {isOtpSent ? (
                <div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {" "}
                    {otpSendMobile != "" ? <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "0.8rem",
                        fontStyle: "italic",
                        fontWeight: "400",
                        lineHeight: "1.3rem",
                        alignItems: "center",
                        color: "#9b97a0",
                      }}
                    >
                      OTP Sent on {props.session.data.login_user_mobile}
                      {/* {otpSendMobile} */}
                    </p> : null}
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "0.8rem",
                        fontStyle: "italic",
                        fontWeight: "400",
                        lineHeight: "1.3rem",
                        alignItems: "center",
                        color: "black",
                      }}
                    >
                      <span style={{ cursor: "pointer", color: seconds == 120 ? "#3397f3" : "#9b97a0" }}
                        onClick={seconds == 120 ? getOtp : null}
                      >resend OTP </span>{seconds == 120 ? null : <span style={{ color: "#9b97a0" }}>in {timerView()}</span>}
                    </p>
                  </div>

                  <div>

                  </div>
                  <br />
                  <Input placeholder="Enter Otp" type="number" onChange={(se, event, type = "otp") =>
                    userData(type, event)} style={{ width: "100%", marginBottom: "1.1rem" }} />
                  <Input
                    placeholder="New Password"
                    type="password"
                    onChange={(se, event, type = "password") =>
                      userData(type, event)

                    }
                    style={{ width: "100%", marginBottom: "1.1rem" }}
                  />
                  <Input
                    placeholder="Confirm New Password"
                    type="password"
                    autoComplete="off"
                    onChange={(se, event, type = "confirmPassword") =>
                      userData(type, event)
                    }
                    style={{ width: "100%", marginBottom: "2rem" }}
                  />
                  <Button
                    onClick={validateUserData}

                    style={{
                      borderRadius: "0.5rem",
                      backgroundColor: "#381568",
                      color: "white",
                      fontFamily: "Poppins",
                      fontSize: "1.125rem",
                      width: "100%",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                    content="Submit"
                  ></Button>
                  {
                    validateMsg != '' ?
                      <Message negative>
                        {validateMsg}
                      </Message>
                      :
                      null
                  }
                  {
                    errorMsg != '' ?
                      <Message negative>
                        {errorMsg}
                      </Message>
                      :
                      null
                  }
                </div>
              ) : (
                <div>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "1.5rem",
                      alignItems: "center",
                      color: "#9b97a0",
                    }}
                  >
                    We will send you an OTP on your registered number <br></br>
                    +91{props.session.data.login_user_mobile}
                  </p>
                  <Button
                    onClick={getOtp}
                    disabled={seconds == 120 ? false : true}

                    style={{
                      borderRadius: "0.5rem",
                      backgroundColor: "#381568",
                      color: "white",
                      fontFamily: "Poppins",
                      fontSize: "1.125rem",
                      width: "100%",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                    content="Get OTP"
                  ></Button>
                </div>
              )}{" "}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
