import { TYPE } from '../actions/types'

const initialState = {
    statesList: [],
    citiesList: [],
    areasList: [],
    hotelDetailsResponse: {},
    hotelData: {},
    addNewBankAccountResponse: {},
    validateGSTResponse: {},
    updateHotelResponse: {},
    uploadImageRes: {},
    uploadFulldayImgRes: {},
    uploadRegDocRes: {},
    loading: false,
    propertyCheckInCheckOutRes: {},
    editHotelInfoRes: {}
}

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.EDIT_HOTEL_INFO:
            state.editHotelInfoRes = response
            return { ...state, response: action.payload }

        case TYPE.GET_STATES:
            if (response.status === "SUCCESS") {
                state.statesList = response.states_list
            }
            else {
                state.statesList = []
            }
            return { ...state, response: action.payload }
        case TYPE.GET_REGIONS:
            return { ...state, response: action.payload }
        case TYPE.FETCH_CITIES:
            return { ...state, response: action.payload }
        case TYPE.GET_CITIES:
            if (response.status === "SUCCESS") {
                state.citiesList = response.cities_list
            }
            else {
                state.citiesList = []
            }
            return { ...state, response: action.payload }
        case TYPE.GET_AREA:
            if (response.status === "SUCCESS") {
                state.areasList = response.areas_list
            }
            else {
                state.areasList = []
            }
            return { ...state, response: action.payload }
        case TYPE.ADD_NEW_CITY:
            return { ...state, response: action.payload }
        case TYPE.ADD_NEW_AREA:
            return { ...state, response: action.payload }
        case TYPE.SEARCH_ADMIN:
            return { ...state, response: action.payload }
        case TYPE.LINK_ADMIN_WITH_HOTEL:
            return { ...state, response: action.payload }
        case TYPE.UPDATE_HOTEL:
            state.updateHotelResponse = response
            return { ...state, response: action.payload }
        case TYPE.GET_HOTEL_DETAILS:
            if (response.status === "SUCCESS") {
                state.hotelDetailsResponse = response
                state.hotelData = response.hotelData[0]
                state.loading = false
            }
            else {
                state.hotelDetailsResponse = {}
                state.hotelData = {}
                state.loading = false
            }
            return { ...state, response: action.payload }
        case TYPE.UPDATE_PROPERTY_CHECKIN_CHECKOUT:
            state.propertyCheckInCheckOutRes = response
            return { ...state, response: action.payload }
        case TYPE.GET_UNLISTED_HOTELS:
            return { ...state, response: action.payload }
        case TYPE.UPDATE_NEARBY_AREAS:
            return { ...state, response: action.payload }
        case TYPE.UPLOAD_HOTEL_IMAGES:
            state.uploadImageRes = action.payload
            return { ...state, response: action.payload }
        case TYPE.GET_CLOSED_HOTELS:
            return { ...state, response: action.payload }
        case TYPE.ADD_NEW_ACCOUNT:
            state.addNewBankAccountResponse = response
            return { ...state, response: action.payload }
        case TYPE.SEARCH_ACCOUNT:
            return { ...state, response: action.payload }
        case TYPE.LINK_ACCOUNT:
            return { ...state, response: action.payload }
        case TYPE.UNLINK_ACCOUNT:
            return { ...state, response: action.payload }
        case TYPE.UPLOAD_REG_DOCS:
            state.uploadRegDocRes = action.payload
            return { ...state, response: action.payload }
        case TYPE.GST:
            state.validateGSTResponse = response
            return { ...state, response: action.payload }
        case "clear_update_hotel_res":
            state.updateHotelRes = {}
            return { ...state, response: action.payload }
        case TYPE.UPLOAD_FULLDAY_DETAILS_IMG:
            state.uploadFulldayImgRes = action.payload
            return { ...state, response: action.payload }
        case "clear_upload_fullday_images_res":
            state.uploadFulldayImgRes = {}
            return { ...state, response: action.payload }
        case "clear_upload_reg_docs":
            state.uploadRegDocRes = {}
            return { ...state, response: action.payload }
        case "clear_update_hotel_res":
            state.updateHotelRes = {}
            return { ...state, response: action.payload }
        case "clear_upload_image_res":
            state.uploadImageRes = {}
            return { ...state, response: action.payload }

        case "clear_edit_hotel_info_res":
            state.editHotelInfoRes = {}
            return { ...state, response: action.payload }
        case "clear_property_checkin_checkout":
            state.propertyCheckInCheckOutRes = {}
            return { ...state, response: action.payload }
        case "clear_hotel_detail_response":
            state.hotelDetailsResponse = {}
            return { ...state, response: action.payload }
        default:
            return state;
    }
}




