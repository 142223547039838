import React, { Component } from "react";

import Header from "../components/Header_footer/Header";
import Footer from "../components/Header_footer/Footer";
import SideNavbar from "../components/Header_footer/Header/SideNavbar";

import { isMobile } from 'react-device-detect';

import { connect } from "react-redux";

class Layout extends Component {
  
  render() {
    return (
      <div>

        <div className="outer-container">

          <Header />

          <div style={{ display: !isMobile ? 'flex' : "", height: "100%", width: "100%" }}>
            <SideNavbar />

            {/*Route Based Components*/}
            {this.props.children}

          </div>

          {isMobile &&
            !this.props.hideHeader &&
            !this.props.hideFooter &&
            <Footer />
          }

        </div>

      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    bankAccountResponse: state.bankAccountResponse,
    hideHeader: state.commonActionsReducer.hideHeader,
    hideFooter: state.commonActionsReducer.hideFooter
  };
};


export default connect(mapStateToProps)(Layout);
