import { TYPE } from "../actions/types";
const intialState = {
  upComingBookingsres: {},
  upComingBookingsApiLoader: true
}

export default function (state = intialState, action, response = action.payload) {
  switch (action.type) {
    case TYPE.GET_BOOKING_HISTORY:
      return { ...state, response: action.payload };
    case TYPE.GET_PENDING_BOOKINGS:
      state.upComingBookingsres = response
      state.upComingBookingsApiLoader = false
      return { ...state, response: action.payload };
    case TYPE.UPDATE_CHECKIN_STATUS:
      return { ...state, response: action.payload };
    case TYPE.DENY_BOOKING:
      return { ...state, response: action.payload };
    case TYPE.GET_PACKAGE_BOOKING_HISTORY:
      return { ...state, response: action.payload };
    case TYPE.BOOKING_VOUCHER:
      return { ...state, response: action.payload };
    case TYPE.GET_BOOKINGS_BY_FILTERS:
      return { ...state, response: action.payload }
    case "clear_brevi_bookings_res":
      state.upComingBookingsres = {}
      state.upComingBookingsApiLoader = true

      return {...state}
    default:
      return state;
  }
}
