import React, { Component } from "react";
import {
  Segment,
  Image,
  Icon,
  Button,
  Menu,
  Sidebar,
  Dropdown,
  Grid,
  Label,
  Popup,
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { Suspense, lazy } from "react";
import {
  USER_DATA,
  SELECTED_HOTEL_ID,
  SELECTED_HOTEL_NAME,
} from "../../../constants";
import { logout } from "../../../redux/actions/user_actions";
import { clearSession } from "../../../redux/actions/session_actions";
import { connect } from "react-redux";
import HotelsDropDown from "../../../hoc/hotelsDropDown";
import { isMobile } from "react-device-detect";
import MenuList from "./menuItem_m";
import * as Constants from "../../../constants";
import newExtranetImages from "../../../assets";
import CustomDropdown from "./CustomDropdown/CustomDropdown";
import SideNavbar from "./SideNavbar";
import Auth from '../../../hoc/auth'
import HeaderComponentWeb from "./headerComponentWeb";

const HeaderComponentMobile = React.lazy(() => import("./headeComponent_m"));

class Header extends Component {
  state = {
    activeItem: "/bookings",
    visible: false,
    label: "My Bookings",
    icon: "calendar alternate",
    financeMenuVisible: false,
    userType: "",
    isAdminAvailable: false,
    inputValue: "",
    //NEW
    isShowMenu: false,
    isShowMenuHover: false,
    isNewBookings: false,
    isDropdownOpen: false,
    down: false,
    up: true,

  };


  onChangeDropdown = (value) => {
    this.setState({ inputValue: value });
  };

  getMenuList = () => {
    let unlistedClosedLabel = "";
    let permissions = this.props.session.data.permissions;
    let isRateForUser =
      this.props.session.data.permissions.uep_user_rate_access !== "na";
    let isSuperAdmin =
      this.props.session.data.user_access_type === "SUPER_ADMIN";
    let isAdmin = this.props.session.data.user_access_type === "ADMIN";
    let menuList = [];
    let subMenulist = [];
    let isHotelClosed = 0;
    if (
      permissions.uep_hotel_listing == 1 &&
      permissions.uep_closed_hotels == 1
    ) {
      unlistedClosedLabel = ["Unlisted/Closed Hotels"];
      isHotelClosed = 1;
    } else if (
      permissions.uep_hotel_listing == 1 &&
      permissions.uep_closed_hotels == 0
    ) {
      unlistedClosedLabel = "Unlisted Hotels";
    } else if (
      permissions.uep_hotel_listing == 0 &&
      permissions.uep_closed_hotels == 1
    ) {
      unlistedClosedLabel = "Closed Hotels";
    }
    if (permissions.uep_view_bookings == "1") {
      menuList.push({
        label: "Bookings",
        pathValue: "/bookings",
        icon: "calendar alternate",
        type: "non-admin",
        isAvailableForUser: true,
      });
    }

    if (isRateForUser || permissions.uep_inventories == "1") {
      menuList.push({
        label: "Manage Rates / Rooms",
        pathValue: "/inventory",
        icon: "building",
        type: "non-admin",
        isAvailableForUser: isRateForUser,
      });
    }
    if (permissions.uep_amenities == "1") {
      menuList.push({
        label: "Amenities",
        pathValue: "/amenities",
        icon: "bed",
        type: "non-admin",
        isAvailableForUser: true,
      });
    }
    menuList.push({
      label: "Policy Updates",
      pathValue: "/policy",
      icon: "file alternate",
      type: "non-admin",
      isAvailableForUser: true,
    });
    if (permissions.uep_finance == "1") {
      menuList.push({
        label: "Hotel Finance",
        pathValue: "/hotelfinance",
        icon: "money bill alternate",
        type: "non-admin",
        isAvailableForUser: true,
      });
    }
    if (permissions.uep_add_manager == "1") {
      menuList.push({
        label: "User Access",
        pathValue: "/user-access",
        icon: "user circle",
        type: "non-admin",
        isAvailableForUser: true,
      });
    }
    if (permissions.uep_hotel_profile == "1") {
      menuList.push({
        label: "Profile",
        pathValue: "/profile",
        icon: "user",
        type: "non-admin",
        isAvailableForUser: true,
      });
    }
    if (isSuperAdmin) {
      menuList.push({
        label: "Go to Admin",
        pathValue: "/admin",
        icon: "long arrow alternate right",
        color: "#3377ab",
        type: "admin",
        isAvailableForUser: true,
        subMenulist: subMenulist,
      });
    }

    return menuList;
  };

  handleDropdown = () => {
    console.log("clicked");
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    this.setState({ inputValue: "" });
  };

  handleMenu = () => {
    console.log(this.isShowMenu);
    this.setState({ isShowMenu: !this.state.isShowMenu });
  };


  handleItemClick = (e, { name, label, value, type }) => {
    if (name == "/admin") {
      window.open(Constants.ADMINCHECKOUT, "_blank");
    } else {
      this.setState({ visible: false });
      this.setState({ activeItem: name });
      this.setState({ label: label });
      this.setState({ icon: value });
      // this.setState({ userType: type })
      this.props.history.push(name);
    }
  };

  handleChangedValue = (event, valueSet) => {
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    let selectedHotel = userData.hotels.find(
      (hotel) => hotel.hotel_id === valueSet.value
    );
    localStorage.setItem(SELECTED_HOTEL_ID, selectedHotel.hotel_id);
    localStorage.setItem(SELECTED_HOTEL_NAME, selectedHotel.hotel_name);
    if (selectedHotel?.agreement_type === "DISPLAY") {
      this.props.history.push("/agreement");
    } else if (
      selectedHotel?.agreement_type !== "DISPLAY" &&
      selectedHotel?.allow_foreign_guest === "DISPLAY"
    ) {
      this.props.history.push("/foreign-guest");
    } else {
      this.props.history.push("/redirect");

      if (isMobile) {
        setTimeout(() => {
          this.props.history.push("/summary");
        }, 2);
      } else {
        setTimeout(() => {
          //NEW
          // this.props.history.push("/");
          this.props.history.push("/bookings");
          this.setState({ isNewBookings: true });
          //

          // this.props.history.push("/bookings");
        }, 2);
      }

      this.setState({
        activeItem: this.getMenuList()[0].pathValue,
        label: this.getMenuList()[0].label,
        icon: this.getMenuList()[0].icon,
      });
    }
  };

  logoutUser = () => {
    this.setState({ isShowMenu: false });
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    let data = {
      login_user_id: userData.login_user_id,
      login_user_name: userData.login_user_name,
      login_user_type: userData.user_access_type,
    };
    this.props.dispatch(logout(data));
    this.props.dispatch(clearSession());
    this.props.history.push("/login");
  };

  isUserHeader = () => {
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    return userData && userData.token && userData.token.length > 20;
  };

  getHotelList = () => {
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    return userData.hotels.map((hotel) => ({
      value: hotel.hotel_id,
      text: hotel.hotel_name,
      key: hotel.hotel_name,
      content: isMobile ? (
        <div style={{ fontSize: "16px", fontWeight: "500" }}>
          {hotel.hotel_name}
        </div>
      ) : (
        <div>{hotel.hotel_name}</div>
      ),
    }));
  };

  sidebarOpenClick = () => {
    this.setState({ visible: !this.state.visible });
  };

  sidebarCloseClick = () => {
    this.setState({ visible: false });
  };

  changePassword = () => {
    this.props.history.push("/password-change");
  };

  changePasswordNew = () => {
    this.setState({ isShowMenu: false });
    this.props.history.push("/password-change");
  };

  //NEW
  handleHover2 = (e) => {
    this.setState({ isShowMenuHover: false });
    this.setState({ isShowMenu: false });
  };

  handleHover = (e, type) => {
    if (type == "in") {
      e.target.style.backgroundColor = "#F6F3FA";
    } else if (type == "out") {
      e.target.style.backgroundColor = "";
    }
  };
  //

  onNavigateToOldUI = () => {
    this.props.history.push("/bookings");
    this.setState({ isShowMenu: false });
  };

  setActiveItem = (item) => {
    this.setState({
      activeItem: item,
    });
  };

  getMenuListFinance = () => {
    let menuList = [];
    menuList.push(
      {
        label: "Ledger",
        name: "Ledger",
        color: "white",
        pathValue: "/finance/ledger",
        key: "Ledger",
        icon: "calendar alternate",
      },
      {
        label: "Hotel Balance List",
        name: "Hotel Balance List",
        pathValue: "/finance/hotel-balance-list",
        color: "white",
        key: "hotelFinance",
        icon: "calendar alternate",
      },
      {
        label: "Invoice Generation",
        name: "Invoice Generation",
        pathValue: "/finance/invoice-generation",
        color: "white",
        key: "Cr/Dr",
        icon: "calendar alternate",
      }
    );
    return menuList;
  };

  upDownHover = (e, type) => {
    if (type == "enter") {
      e.target.style.height = "2rem";
    } else {
      e.target.style.height = "1.5rem";
    }
  };

  financeSidebarAction = () => {
    this.setState({ financeMenuVisible: !this.state.financeMenuVisible });
  };

  render() {
    const { activeItem } = this.state;

    return (
      < >

        {/* {Constants.debug == 0 ? (
          <></>
        ) : (
          <div
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
              backgroundColor: "#fff",
            }}
          >
            {Constants.debug == 6 ? "Adex" : "Test"}
          </div>
        )} */}

        {
          this.props.session && this.props.session.isSessionValid ? (
            isMobile ?
              (
                this.props.location.pathname === "/create-room-category" ||
                  this.props.location.pathname.includes("/room-category") ||
                  this.props.location.pathname.includes("/rate-plan") ||
                  this.props.location.pathname === "/internal-server-error" ? null : (
                  <div>
                    <Suspense fallback>
                      <HeaderComponentMobile
                        logoutUser={this.logoutUser}
                        changePassword={this.changePassword}
                        getHotelList={this.getHotelList}
                        getMenuList={this.getMenuList}
                        handleItemClick={this.handleItemClick}
                        handleChangedValue={this.handleChangedValue}
                        sidebarOpenClick={this.sidebarOpenClick}
                        sidebarCloseClick={this.sidebarCloseClick}
                        state={this.state}
                        hideHeader={this.props.hideHeader}
                        hotelList={JSON.parse(localStorage.getItem(USER_DATA))}
                        {...this.props}
                      />
                    </Suspense>
                  </div>
                )
              )
              :
              <HeaderComponentWeb {...this.props} />
          )
            :
            isMobile ?
              (
                <div
                  style={{
                    backgroundColor: "#381568",

                    width: "100vw",
                    maxHeight: "14vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingTop: 25,
                    paddingLeft: 20,
                    paddingBottom: 20,
                  }}
                >
                  <Image
                    src="https://s3.ap-south-1.amazonaws.com/site-img-res-new/logo-mail.png"
                    wrapped
                    style={{
                      width: "50%",
                    }}
                  />
                </div>
              ) : null

        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session.response,
    hideHeader: state.commonActionsReducer.hideHeader,
  };
};
export default connect(mapStateToProps)(withRouter(Header));
