import axios from 'axios';

import { TYPE } from './types';

import * as Constants from '../../constants';


export function login(data) {
    const loginApi = Constants.HOTEL_API + '/' + TYPE.LOGIN;
    const request = axios
        .post(loginApi, data)
        .then(response => response.data);
    return {
        type: TYPE.LOGIN,
        payload: request
    }
}



export function loginOtp(data) {
    const loginApi = Constants.HOTEL_API + '/' + TYPE.VERIFY_OTP_LOGIN;
    const request = axios
        .post(loginApi, data)
        .then(response => response.data);
    return {
        type: TYPE.VERIFY_OTP_LOGIN,
        payload: request
    }
}

export function logout(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const logoutApi = Constants.HOTEL_API + '/' + TYPE.LOGOUT;
    const request = axios
        .post(logoutApi, data, { headers: { "Authorization": `Bearer ${apiToken}` } })
        .then(response => response.data);
    return {
        type: TYPE.LOGOUT,
        payload: request
    }
}

export function changePassword(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const logoutApi = Constants.HOTEL_API + '/' + TYPE.CHANGE_PASSWORD;
    const request = axios
        .post(logoutApi, data, { headers: { "Authorization": `Bearer ${apiToken}` } })
        .then(response => response.data);
    return {
        type: TYPE.CHANGE_PASSWORD,
        payload: request
    }
}

export function sendOtpToChangePassword(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const logoutApi = Constants.HOTEL_API + '/' + TYPE.SEND_OTP_TO_CHANGE_PASSWORD;
    const request = axios
        .post(logoutApi, data, { headers: { "Authorization": `Bearer ${apiToken}` } })
        .then(response => response.data);
    return {
        type: TYPE.SEND_OTP_TO_CHANGE_PASSWORD,
        payload: request
    }
}


export function changePasswordUsingOtp(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const logoutApi = Constants.HOTEL_API + '/' + TYPE.CHANGE_PASSWORD_USING_OTP;
    const request = axios
        .post(logoutApi, data, { headers: { "Authorization": `Bearer ${apiToken}` } })
        .then(response => response.data);
    return {
        type: TYPE.CHANGE_PASSWORD_USING_OTP,
        payload: request
    }
}


export function agreeToPolicy(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const api = Constants.HOTEL_API + '/' + TYPE.POLICY_AGREE_DENY;
    const request = axios
        .post(api, data, { headers: { "Authorization": `Bearer ${apiToken}` } })
        .then(response => response.data);
    return {
        type: TYPE.POLICY_AGREE_DENY,
        payload: request
    }
}


export function getUsersHotel(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const api = Constants.HOTEL_API + '/' + TYPE.GET_USERS_HOTEL.ENDPOINT;
    const request = axios
        .post(api, data, { headers: { "Authorization": `Bearer ${apiToken}` } })
        .then(response => response.data);
    return {
        type: TYPE.GET_USERS_HOTEL.GET,
        payload: request
    }
}

export function updateManagerData(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const api = Constants.HOTEL_API + '/' + TYPE.UPDATE_MANAGER_DATA.ENDPOINT;
    const request = axios
        .post(api, data, { headers: { "Authorization": `Bearer ${apiToken}` } })
        .then(response => response.data);
    return {
        type: TYPE.UPDATE_MANAGER_DATA.GET,
        payload: request
    }
}
