import { TYPE } from "../actions/types";

const intialState ={
    getRoomPlanPolicyRes : {}
}
export default function(state = intialState, action) {
  switch (action.type) {
    case TYPE.GET_ROOM_PLAN_REFUND_POLICY:
      state.getRoomPlanPolicyRes = action.payload
      return { ...state, response: action.payload };
    case "clear_get_room_plan_policy_res":
      state.getRoomPlanPolicyRes = {}
      return { ...state, response: action.payload} ;

    default:
      return state;
  }
}
