import React from 'react';
import ReactDOM from 'react-dom';
import './resources/css/styles.css'
import { HashRouter,BrowserRouter } from 'react-router-dom';
// import { createHashHistory } from 'history';
import 'semantic-ui-css/semantic.min.css'
import { createBrowserHistory } from 'history';
import Routes from './routes';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import Reducer from './redux/reducers';
import * as Constants  from './constants';

const createStoreWithMiddleware = applyMiddleware(promiseMiddleware, ReduxThunk)(createStore);
// let hashHistory = createHashHistory();
let browserHistory = createBrowserHistory();
console.log("rea", React.version)
if(Constants.debug ==0){
    console.log = function () { } 
}
ReactDOM.render(
    <Provider store={createStoreWithMiddleware(Reducer)}>
        <BrowserRouter history={browserHistory} >
            <Routes />
        </BrowserRouter>
    </Provider>

    , document.getElementById('root'));
// , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() For debugging
// 