import { TYPE } from '../actions/types'
const intialState={
    bookingData :{},
    ledgerData:{},
}
export default function (state =intialState, action) {
    switch (action.type) {
        case TYPE.DOWNLOAD_BOOKING_DATA_EXTRANET:
            state.bookingData=action.payload
            return { ...state, response: action.payload }
        case TYPE.DOWNLOAD_LEDGER:
            state.ledgerData=action.payload
            return { ...state, response: action.payload }
        case "clear_booking_excel_data":
            state.bookingData={}
            return {...state,response: action.payload}
        case "clear_ledger_excel_data":
            state.ledgerData={}
            return {...state,response: action.payload}
        default:
            return state;
    }
}

