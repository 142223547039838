const serverHost = "https://extranet.brevistay.com/adex";
// const devServer = "http://192.168.74.199";
// const localHost = "http://localhost";
const extranetStaging = "https://extranet-staging.brevistay.com/internal"
const extranetLive = "https://extranet.brevistay.com/internal"
const extranetLocalHost = "http://localhost:3000/internal"
const devServer1 = "https://t1.sandbox.brevistay.com"
const devServer2 = "https://t2.sandbox.brevistay.com";
const devServer3 = "https://t3.sandbox.brevistay.com";
const devServer4 = "https://t4.sandbox.brevistay.com";
const devServer5 = "https://t5.sandbox.brevistay.com";
const adex = "https://staging.adex.brevistay.com";

export const debug = 0 // CHANGE THIS TO 0 before deploying to live server
//(api,internalRoute)=> 0->(LIVE, LIVE) 1->(TEST,STAGING) 2->(TEST,LOCALHOST)

export const HOST = debug == 0 ? serverHost
  : debug == 1 ? devServer1
    : debug == 2 ? devServer2
      : debug == 3 ? devServer3
        : debug === 4 ? devServer4 : debug === 5 ? devServer5 : adex;

export const ADMINCHECKOUT = "https://admin.brevistay.com"
// debug == 0 ? extranetLive : debug === 1 ? extranetStaging  : extranetLocalHost;

export const HOTEL_API = HOST + "/wss/hotel";
export const SUPER_ADMIN_API = HOST + "/api/sa";
export const FINANCE = HOST + "/finance";
export const MARKETING = HOST + "/marketing"
export const USER_DATA = "user_data";
export const PAYMENT = HOST + "/payments"
export const SELECTED_HOTEL_ID = "selected_hotel_id";
export const SELECTED_HOTEL_NAME = "selected_hotel_name";
export const IS_FIRST_RENDER = "is_first_render";

export const API_RESPONSE = {
  SESSION_ERROR: "401",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};
export const SESSION_ERROR = "session_error";

