import { TYPE } from '../actions/types'

const initialState = {
    refundPolicies: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case TYPE.GET_REFUND_POLICIES:
            state.refundPolicies = action.payload;
            return {...state,response:action.payload}
        case TYPE.GET_HOTEL_REFUND_POLICIES.POST:
            return {...state,response:action.payload}
        case TYPE.UPDATE_BULK_HOTEL_REFUND_POLICY.POST:
            return { ...state, response: action.payload }
        default:
            return state;
    }
}

