import  { API_RESPONSE} from '../../constants';

export const actionResponseHandler=(response,props)=>{
    return new Promise((resolve,reject)=>{
            if(response.status===API_RESPONSE.SUCCESS){
                resolve(response);
            }else{
                reject(response)
            }
    });
}
export const actionErrorHandler=(error,props)=>{
    return new Promise((resolve,reject)=>{
            if(error.response!=null && error.response.status==API_RESPONSE.SESSION_ERROR){
                props.history.push('/login');
            }else{
                resolve("NETWORK ERROR");    
            }
            
    });
}
