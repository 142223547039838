import { TYPE } from '../actions/types'

const initialState = {
    draftHotelsList: [],
    updateDraftHotelRes: {},
    draftHotelDetails: {}
};

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.DRAFT_HOTELS.POST:
            state.draftHotelsList = response
            return {
                ...state
            }
        case TYPE.UPDATE_OR_CREATE_DRAFT_HOTEL.POST:
            state.updateDraftHotelRes = response
            return {
                ...state
            }
        case TYPE.DRAFT_HOTEL_DETAILS.POST:
            state.draftHotelDetails = response
            return {
                ...state
            }
        case TYPE.DELETE_DRAFT_HOTEL:
            state.deleteDraftHotel=response
            return {
                ...state
            }
        default:
            return state;
    }
}

