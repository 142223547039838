import { combineReducers } from "redux";
import userResponse from "./user_reducer";
import bookingResponse from "./booking_reducer";
import availabilityResponse from "./availability_reducer";
import rateResponse from "./rates_reducer";
import roomResponse from "./rooms_reducer";
import session from "./session_reducer";
import hotelProfile from "./profile_reducer";
import settingUpdate from "./sa_reducer";
import amentiesResponse from "./amenties_reducers";
import policyUpdateResponse from "./policy_reducer";
import checkinResponse from "./checkIn_reducers";
import updateCheckinResponse from "./checkIn_reducers"
import userAccessResponse from "./userAccess_reducer"
import hotelListingResponse from "./hotel_listing_reducer"
import bankAccountResponse from "./bank_account_reducer";
import adminResponse from "./admin_reducer"
import hotelFinanceResponse from "./finance_reducer";
import agreementResponse from "./agreement_reducers";
import { commonActionsReducer } from "./common-actions-reducer";
import refundPoliciesResponse from "./refund_policies_reducer"
import draftHotelsReponse from "./draft_hotels_reducer"
import showBookings from './show_bookings_reducer';
import downloadExcelResponse from "./download_excel_reducer";
import holidaBookingsResponse from "./holida_bookings_reducer"
import fulldayInventoryResponse from "./full_day_inventory_reducers";
import roomListingResponse from "./room_listing_reducer";
import roomCategoryResponse from "./room_category_reducer";
import downloadAgreement from "./download_agreement_reducer";
import getRoomPlanRefundPolicyRes from "./get_dummy_refund_policy_reducer";
const rootReducer = combineReducers({
  getRoomPlanRefundPolicyRes,
  fulldayInventoryResponse,
  roomCategoryResponse,
  roomListingResponse,
  downloadAgreement,
  downloadExcelResponse,
  showBookings,
  userResponse,
  bookingResponse,
  availabilityResponse,
  rateResponse,
  roomResponse,
  session,
  hotelProfile,
  settingUpdate,
  amentiesResponse,
  policyUpdateResponse,
  checkinResponse,
  updateCheckinResponse,
  userAccessResponse,
  hotelListingResponse,
  bankAccountResponse,
  adminResponse,
  hotelFinanceResponse,
  agreementResponse,
  commonActionsReducer,
  refundPoliciesResponse,
  draftHotelsReponse,
  holidaBookingsResponse
  });

export default rootReducer;
