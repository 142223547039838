import React from "react";
import { Menu, Icon } from "semantic-ui-react";

const MenuItemMobile = props => {
  const renderMenuItem = () => {
    return props.menuList.map(menu => (
      <Menu.Item
        key={menu.pathValue}
        style={{
          color: "#4B3274",
          padding: "1em",
        }}
        name={menu.pathValue}
        value={menu.icon}
        label={menu.label}
        active={props.activeItem === menu.pathValue}
        onClick={props.handleItemClick}
      >

        <Icon name={menu.icon} />

        {menu.label}

      </Menu.Item>
    ));
  };

  return <div style={{ marginTop: "2.5em" }}>{renderMenuItem()}</div>;
};

export default MenuItemMobile;
