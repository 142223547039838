import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePassword, changePasswordUsingOtp, login, loginOtp, sendOtpToChangePassword } from '../../redux/actions/user_actions';
import { USER_DATA, SELECTED_HOTEL_ID, SELECTED_HOTEL_NAME } from '../../constants';
import { actionResponseHandler, actionErrorHandler } from '../utils/responseHandler'
import FormFieldsNew from '../utils/Forms/formfieldsNew'
import { withRouter } from 'react-router-dom';
import { isMobile } from "react-device-detect"
import { Message, Segment, Form, Grid, Button, Input, Image, Header, Card, Icon, Menu } from 'semantic-ui-react';
import { hideHeader } from '../../redux/actions/common_action';
import newExtranetImages from "../../assets";

class ChangePasswordMobile extends Component {
    componentDidMount = () => {

    }

    state = {
        formData: {
            old_password: {
                element: "input",
                value: '',
                label: false,
                labelText: "Old Password",
                config: {
                    name: "password_input",
                    type: "password",
                    placeholder: "Enter your Password"
                },
                validation: {
                    required: true,
                    minLen: 6
                },
                valid: false,
                touched: false,
                validationMessage: ''
            },
            new_password: {
                element: "input",
                value: '',
                label: false,
                labelText: "New Password",
                config: {
                    name: "password_input",
                    type: "password",
                    placeholder: "Enter New Password"
                },
                validation: {
                    required: true,
                    minLen: 6
                },
                valid: false,
                touched: false,
                validationMessage: ''
            },
            confirm_password: {
                element: "input",
                value: '',
                label: false,
                labelText: "Confirm Password",
                config: {
                    name: "password_input",
                    type: "password",
                    placeholder: "Confirm New Password"
                },
                validation: {
                    required: true,
                    minLen: 6
                },
                valid: false,
                touched: false,
                validationMessage: ''
            }
        },
        open: false,
        vertical: 'bottom',
        second: 120,
        horizontal: 'center',
        errorMsg: '',
        usePassword: false,
        isFieldDisabled: true,
        otp: "",
        otpSendMobile: "",
        password: "",
        confirmPassword: "",
        validateMsg: ""
    }

    updateForm = (newState) => {
        this.setState({ formData: newState });
    }
    otpView = () => {
        this.setState({
            usePassword: !this.state.usePassword
        })
    }

    submitForm = (event) => {
        event.preventDefault();
        let dataToSubmit = {};
        let formIsValid = true;
        for (let key in this.state.formData) {
            dataToSubmit[key] = this.state.formData[key].value;
        }
        for (let key in this.state.formData) {
            formIsValid = this.state.formData[key].valid && formIsValid;
        }
        if (formIsValid) {
            this.changePassword_m(dataToSubmit);
        } else {
            this.showSnackBar("Please enter data in all fields");
        }
    }

    changePassword_m = (data) => {

        data.user_access_type = this.props.session.data.user_access_type;
        data.hotel_id = this.props.session.data.login_user_id;
        let response = null;
        console.log("changepassword", data)
        this.props.dispatch(changePassword(data)).then(() => {
            response = this.props.passwordChange.response;
            actionResponseHandler(response, this.props).then((response) => {
                this.showSnackBar(response.msg);
                this.props.history.push("/");
            }).catch((response) => {
                this.showSnackBar(response.msg);
            });
        }).catch((error) => {
            actionErrorHandler(error, this.props).then((error) => {
                this.showSnackBar(response.msg);
            });
        })
    }

    showSnackBar = (message) => {
        this.state.errorMsg = message;
        this.displaySnackBar();
    }

    displaySnackBar = state => {
        this.setState({ open: true, ...state });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    timerView = () => {
        let minute = ""
        let second = ""
        if (this.state.second == 120) {
            minute = "02"
        }
        else if (
            this.state.second < 120
            &&
            this.state.second > 59) {
            minute = "01"
        }
        else {
            minute = "00"
        }
        if (
            this.state.second == 120
            ||
            this.state.second == 60
        ) {
            second = "00"
        }
        else if (
            this.state.second < 69
            &&
            this.state.second > 60
        ) {
            second = this.state.second - 60
        }
        else if (
            this.state.second < 120
            &&
            this.state.second > 60
        ) {
            second = this.state.second - 60
        }
        else {
            second = this.state.second
        }
        return (
            this.state.second < 70
                &&
                this.state.second > 60
                ||
                this.state.second < 10
                &&
                this.state.second >= 0
                ?
                <span style={{ float: "left", fontSize: "0.9rem", fontFamily: "Poppins", fontWeight: 400 }}>
                    {minute}:0{second}
                    <p style={{ color: "green" }}>
                        {this.state.otpSentMsg}
                    </p>
                </span> :
                <span style={{ float: "left", fontSize: "0.9rem", fontFamily: "Poppins", fontWeight: 400 }}>
                    {minute}:{second}
                    <p style={{ color: "green" }}>
                        {this.state.otpSentMsg}
                    </p>
                </span>
        )
    }

    getOtp = () => {
        this.timer();
        this.setState({
            isFieldDisabled: false,
            otpSentMsg: "Otp Sent",
            otpSendMobile: ""
        })
        const data = {
            "user_id": this.props.session.data.login_user_id
        }
        console.log("dispatchingData", data)
        let response = null;
        this.props.dispatch(sendOtpToChangePassword(data)).then((response) => {
            // response = this.props.passwordChange.response;
            this.setState({
                otpSendMobile: response.payload.msg
            })
            actionResponseHandler(response, this.props).then((response) => {
                console.log("Otp get", response)
                this.setState({
                    otpSendMobile: response.payload.msg
                })
            }).catch(() => {
            });
        }).catch((error) => {
            actionErrorHandler(error, this.props).then((error) => {
            });
        })
        setTimeout(() => this.setState({
            otpSentMsg: "",

        }), 5000);
        setTimeout(() => this.setState({
            otpSendMobile: "",

        }), 10000);
    }



    timer = () => {
        this.interval = setInterval(
            () => this.state.second == 0
                ?
                clearInterval(this.interval, this.setState({ second: 120 }))
                :
                this.setState({ second: this.state.second - 1 }
                ), 1000);
    }



    userData = (type, event) => {
        this.setState({
            validateMsg: ""
        })


        if (type == "otp") {
            this.setState({
                otp: event.value
            })
        }
        else if (type == "password") {
            this.setState({
                password: event.value
            })
        }
        else {
            this.setState({
                confirmPassword: event.value
            })
        }

    };

    validateUserData = () => {
        let msg = ""
        if (this.state.confirmPassword.length >= 6 && this.state.password.length >= 6) {
            if (this.state.confirmPassword == this.state.password) {
                this.changePasswordUsingOtp_m();
            }
            else (
                msg = "Passwords Doesn't Match"
            )
        }
        else {
            msg = "Password Must be greater than 6 Characters"
        }
        this.setState({
            validateMsg: msg
        })

    }

    changePasswordUsingOtp_m = () => {
        this.timer();
        this.setState({
            isFieldDisabled: false,
        })
        const data = {
            "user_id": this.props.session.data.login_user_id,
            "otp": this.state.otp,
            "new_password": this.state.password,
            "confirm_password": this.state.confirmPassword
        }
        console.log("dispatchingData", data)
        let response = null;
        this.props.dispatch(changePasswordUsingOtp(data)).then((response) => {
            // response = this.props.passwordChange.response;
            console.log(response);
            if( response.payload.is_password_changed){
                this.props.history.push("/"); 
            }
            actionResponseHandler(response, this.props).then((response) => {
                console.log("response", response)
                this.showSnackBar(response.payload.otp_res_msg
                    );
                this.props.history.push("/");
            }).catch((response) => {
                this.showSnackBar(response.payload.otp_res_msg
                    );
            });
        }).catch((error) => {
            actionErrorHandler(error, this.props).then((error) => {
                this.showSnackBar(response.payload.otp_res_msg
                    );
            });
        })

    }
    handleClose = () => {
        document.body.setAttribute('style', '')
        this.props.setOpenModal(false)
        // window.scrollTo(0, windowOffset)
    }
    render() {
        const { errorMsg, vertical, horizontal, open } = this.state;
        return (
            <div>

                <div className="modal_bottom">
                    <div className="modal-content">
                        <Header style={{ fontSize: "1.5rem", fontFamily: "Poppins", color: "#1C1C1C", fontWeight: 500, padding: "1rem", paddingTop: "1.4rem", marginBottom: 0 }}>

                            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                                <span>Change Password</span>
                                <span>
                                    <span
                                      onClick={() => this.handleClose()}
                                    >
                                        <Image src='/assets/close.svg' style={{ width: "2rem", float: "right", marginRight: "0.5rem" }} />
                                    </span>
                                </span>
                            </div>
                        </Header>

                        <Grid style={{ padding: "1rem" }}>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                {
                                this.state.otpSendMobile != '' ?
                                    <Message positive style={{fontFamily:"Poppins", fontWeight:500, fontSize:"1rem"}}>
                                        {this.state.otpSendMobile}
                                    </Message>
                                    :
                                    null
                            }
                                </Grid.Column>
                                <Grid.Column style={{ marginTop: "0.5rem" }}>
                                    <div>
                                        {this.timerView()}

                                        <Button floated="right" size="large" onClick={() => this.getOtp()}
                                            // color="teal"
                                            disabled={this.state.second == 120 ? false : true}
                                            style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "0.9rem",background: "#381568",color:"white" }}
                                        >
                                            GET OTP
                                        </Button>
                                    </div>
                                </Grid.Column>
                                <Grid.Column style={{ marginTop: "1rem" }}>

                                    <Input
                                        placeholder='Enter OTP'
                                        autocomplete="off"
                                        type="number"
                                        onChange={(se, event, type = "otp") => this.userData(type, event)}
                                        fluid

                                        style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "1.2rem", borderRadius: "10px" ,height: "3rem"}}>

                                    </Input>
                                </Grid.Column>
                                <Grid.Column style={{ marginTop: "0.5rem" }}>

                                    <Input
                                        type="password"
                                        autoComplete="off"
                                        onChange={(se, event, type = "password") => this.userData(type, event)}
                                        fluid
                                        placeholder="Enter new password"
                                        style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "1.2rem", borderRadius: "10px",height: "3rem" }}>

                                    </Input>
                                </Grid.Column>
                                <Grid.Column style={{ marginTop: "0.5rem" }}>

                                    <Input
                                        type="password"
                                        fluid
                                        autoComplete="off"
                                        onChange={(se, event, type = "confirmPassword") => this.userData(type, event)}
                                        placeholder="Confirm new password" style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "1.2rem", borderRadius: "10px",height: "3rem" }}>

                                    </Input>
                                </Grid.Column>
                                <Grid.Column style={{ marginBottom: "0.5rem" }}>
                                    <Button
                                        // disabled={searchFlag}
                                        fluid
                                        size="large"
                                        style={{
                                            width: "100%",
                                             background: "#381568",
                                            fontSize: "1.5rem", borderRadius: "10px", height: "3.5rem", alignItems: "center", justifyContent: "center", fontFamily: "Poppins", marginTop: "1.2rem", color: "white", display: "flex", fontWeight: 500
                                        }}

                                        onClick={this.validateUserData}
                                    > Save Changes</Button>
                                </Grid.Column>
                                <Grid.Column>
                                {
                            this.state.validateMsg != '' ?
                                <Message negative style={{fontFamily:"Poppins", fontWeight:500, fontSize:"1rem"}}>
                                    {this.state.validateMsg}
                                </Message>
                                :
                                null
                        }
                        {
                            errorMsg != '' ?
                                <Message negative style={{fontFamily:"Poppins", fontWeight:500, fontSize:"1rem"}}>
                                    {errorMsg}
                                </Message>
                                :
                                null
                        }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginState: state.userResponse,
    }
}

export default connect(mapStateToProps)(withRouter(ChangePasswordMobile));