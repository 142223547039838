import { TYPE } from "../actions/types";

const intialState = {
  profileResponse: {},
  isApiLoading : true
}

export default function (state = intialState, action, response = action.payload) {
  switch (action.type) {
    case TYPE.SA_GET_BASIC_SETTINGS:
      if (response.status === "SUCCESS") {
        state.profileResponse = response
        state.isApiLoading = false
      }
      else if (response.status === "ERROR") {
        state.isApiLoading = false
      }
      return { ...state, response: action.payload };
    case TYPE.WELCOME:
      return { ...state, response: action.payload };
    case "clear_hotel_data_response":
      state.isApiLoading = true
      state.profileResponse = {}

      return { ...state }
    default:
      return state;
  }
}
