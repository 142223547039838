import { TYPE } from '../actions/types'
const intialState={
    agreementRes:{},
   }
export default function (state =intialState, action) {
    switch (action.type) {
        case TYPE.DOWNLOAD_CLICK_WRAP_AGREEMENT:
            state.agreementRes=action.payload
            return { ...state, response: action.payload }
        case "clear_agreement_res":
            state.agreementRes={}
            return {...state,response: action.payload}
        default:
            return state;
    }
}

