import { TYPE } from "../actions/types";
const initialState = {
    bookingsRes: {},
    bookingsList: [],
    loading: true,
    bookingDetailsObj: {},
    isLoadingBookingDetails: false,
    bookingsHistoryRes: {},
    bookingHistoryList: [],
    bookingHistoryLoader: true,
    searchBookingRes: {},
    searchBookingsList: [],
    searchBookingsLoader: true,
    holidaBookingDetailsRes: {},
    holidaBookingDetailsLoader: true,
    holidaBookingRoomPlansList: [],
    holidaBookingDetails: {},
    upcomingBookingsApiLoader: true
};

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.GET_PENDING_PROPERTY_BOOKINGS:
            state.bookingsRes = response
            state.loading = false

            if (response.status === "SUCCESS" && response.bookings && response.bookings.length >= 1) {
                state.bookingsList = response.bookings
            }
            return {
                ...state,
                response: action.payload
            }
        case TYPE.GET_PAST_PROPERTY_BOOKINGS:
            state.bookingsHistoryRes = response
            state.bookingHistoryLoader = false

            if (response.status === "SUCCESS" && response.bookings && response.bookings.length >= 1) {
                state.bookingHistoryList = response.bookings
            }
            return {
                ...state,
                response: action.payload
            }
        case TYPE.GET_PROPERTY_BOOKINGS_BY_FILTERS:
            state.searchBookingRes = response
            state.searchBookingsLoader = false

            if (response.status === "SUCCESS" && response.bookings && response.bookings.length >= 1) {
                state.searchBookingsList = response.bookings
            }

            return {
                ...state,
                response: action.payload
            }
        case TYPE.GET_PROPERTY_BOOKINGS_DETAILS:
            state.holidaBookingDetailsRes = response
            state.holidaBookingDetailsLoader = false
            state.holidaBookingDetails = response.data


            if (response.status === "SUCCESS" && response.data && response.data.room_plans && response.data.room_plans.length >= 1) {
                state.holidaBookingRoomPlansList = response.data.room_plans
            }

            return {
                ...state,
                response: action.payload
            }
        case "clear_holida_bookings_res":
            state.bookingsRes = {}
            state.bookingsHistoryRes = {}
            state.bookingsList = []
            state.bookingHistoryList = []
            state.loading = true
            console.log("clear bookings response");
            return {
                ...state
            }
        default:
            return state;
    }
}
